<template>
  <div class="zchjs">
    <div class="header">
      <div class="h1">物流服务</div>
      <div class="h2">诚信物流     品质仓储</div>
    </div>
    <div class="">
    </div>
    <div class="zhuti flex">
      <div class="news">
        <div>
          <div class="newsbtn" @click="gohref(3)"><span>物流需求发布</span></div>
          <div class="newsbtn bgcolor" @click="gohref(4)"><span>闲置车辆发布</span></div>
        </div>
      </div>
      <div style="position: relative">
        <el-image style="width: 786px;height: 284px" :src="wuliu"></el-image>
        <div class="fw fw2" @click="tabchoose('m2')">
          <div style="margin-top: 15px">物流服务</div>
          <div class="ying">Logistics services</div>
        </div>
      </div>
    </div>
    <div class="zhuti flex">
      <div style="position: relative">
        <el-image style="width: 786px;height: 284px" :src="cangku"></el-image>
        <div class="fw fw1" @click="tabchoose('m1')">
          <div style="margin-top: 15px">仓储服务</div>
          <div class="ying">Storage services</div>
        </div>
      </div>
      <div class="news"  id="m1">
        <div>
          <div class="newsbtn" @click="gohref(1)"><i class="newsbg"></i><span>仓库出租</span></div>
          <div class="newsbtn bgcolor" @click="gohref(2)"><i class="newsbg newsbg1"></i><span>我要找仓</span></div>
        </div>
      </div>
    </div>
    <div class="bgf" id="m1">
      <div class="zhuti ">
        <div class="titles">仓储服务</div>
      </div>
    </div>
    <div class="bgf">
      <div class="zhuti">
          <div class="flex ckdata">
            <div class="ckleft">
              <div class="font24"><i class="img"></i><span>仓库服务数据</span></div>
              <el-row style="text-align: center">
                <el-col  :span="8">
                  <div class="cktitle">仓库入住率</div>
                  <div  class="cknumber"> <VueCountUp :start-value="0" end-value="60">
                  </VueCountUp>%</div>
                </el-col>
                <el-col  :span="8">
                  <div class="cktitle">仓库总面积</div>
                  <div  class="cknumber">
                    <VueCountUp :start-value="0" :end-value="datas.releaseAreaSum">
                    </VueCountUp>
                    ㎡
                  </div>
                </el-col>
                <el-col  :span="8">
                  <div class="cktitle">累计仓库仓源</div>
                  <div  class="cknumber">
                    <VueCountUp :start-value="0" :end-value="datas.releaseSum">
                    </VueCountUp>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div class="ckleft ckright">
              <div class="font24"><i class="img_1"></i><span>仓库需求数据</span></div>
              <el-row style="text-align: center">
                <el-col  :span="8">
                  <div class="cktitle">仓库闲置率</div>
                  <div  class="cknumber">
                    <VueCountUp :start-value="0" end-value="40">
                    </VueCountUp>
                    %
                  </div>
                </el-col>
                <el-col  :span="8">
                  <div class="cktitle">需求总面积</div>
                  <div  class="cknumber">
                    <VueCountUp :start-value="0" :end-value="datas.demandAreaSum">
                    </VueCountUp>
                    ㎡
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="cktitle">累计仓库需求</div>
                  <div  class="cknumber">
                    <VueCountUp :start-value="0" :end-value="datas.demandSum">
                    </VueCountUp>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
      </div>
    </div>
    <div class="zhuti flex">
        <div class="remenck">
          <div class="mg24">
            <div class="cktitle"><i class="reicon"></i><span>热门仓库展示</span><span class="more" @click="golist(1)">查看更多<i class="el-icon-arrow-right"></i></span></div>
            <div>
              <el-tabs style="margin-top: 15px" v-model="activeName" @tab-click="loadlist1">
                <el-tab-pane :label="li.title" :name="li.id" v-for="(li,index) in warehouseTypeList" :key="index"></el-tab-pane>
              </el-tabs>
              <el-empty v-if="arr.length==0"></el-empty>
              <div v-else>
                <div  class="cklist" v-for="(item,index) in arr" @click="goxq(item)" :key="index">
                  <div class="image"><el-image :src="item.mainImages"></el-image></div>
                  <div class="title text-overflow">{{item.name}}</div>
                  <div class="money">{{item.rentPrice}} 元/{{item.rentUnit}}</div>
                  <div class="text-overflow"><span>可租面积{{item.rentalArea}}㎡</span> <span class="address"> <i class="el-icon-location-outline"></i>{{item.areaName}}</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="newck">
         <div class="mg24">
           <div class="cktitle"><span>新加入仓库</span><span class="more" @click="golist(1)">查看更多<i class="el-icon-arrow-right"></i></span></div>
           <div>
             <div  class="cklist flex" v-for="(item,index) in newReleaseList" @click="goxq(item)" :key="index">
               <div class="image "><el-image :src="item.mainImages"></el-image></div>
               <div>
                 <div class="title text-overflow">{{item.name}}</div>
                 <div class="money">{{item.rentPrice}} 元/{{item.rentUnit}}</div>
                 <div>可租面积{{item.rentalArea}}㎡</div>
                 <div class="text-overflow  address"> <i class="el-icon-location-outline"></i><span>{{item.areaName}}</span></div>
               </div>
             </div>
           </div>
         </div>
        </div>
    </div>
    <div class="typebg">
        <div class="zhuti ckxuqiu">
          <div class="xuqiutitle">最新仓库需求<el-button  style="float: right" type="primary" @click="golist(2)">查看更多</el-button></div>
          <div class="shichang flex">
            <div class="scleft">
              <div class="ul" :class="{'checked':number==index}" @click="choosecheck(index)" v-for="(li,index) in rentalTypeList" :key="index">
                {{li.title}}
              </div>
            </div>
            <div class="flex-1" style="padding: 20px;overflow: hidden">
              <el-row class="ul0">
                <el-col :span="15">标题</el-col>
                <el-col :span="7"  :offset="1">可租面积</el-col>
                <el-col :span="1"></el-col>
              </el-row>
              <el-empty v-if="arr1.length==0" :image-size="0"></el-empty>
              <el-row v-else  class="ul1" v-for="(li,index) in arr1" :key="index">
                <el-col :span="15"  class="text-overflow">{{li.name}}</el-col>
                <el-col :span="7"  :offset="1">{{li.rentalArea}}</el-col>
                <el-col :span="1" style="cursor: pointer"><i @click="goxqs(li)"  class="el-icon-arrow-right"></i></el-col>
              </el-row>
            </div>
          </div>
        </div>
    </div>
<!--    <div class="bgf">-->
<!--      <div class="zhuti baojia flex">-->
<!--        <div class="flex-1">-->
<!--          <div class="cktitle baojiadt">仓储信息咨询</div>-->
<!--          <div>-->
<!--            <el-table-->
<!--                :data="consultList"-->
<!--                @row-click="rowclick"-->
<!--                style="width: 100%">-->
<!--              <el-table-column prop="enterpriseName" label="标题">-->
<!--              </el-table-column>-->
<!--              <el-table-column prop="name" label="用途类型">-->
<!--                供方-->
<!--              </el-table-column>-->
<!--              <el-table-column prop="contacts" label="租售类型">-->
<!--              </el-table-column>-->
<!--              <el-table-column prop="phone" label="发布时间">-->
<!--              </el-table-column>-->
<!--            </el-table>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="bgf" id="m2">
      <div class="zhuti ">
        <div class="titles">物流服务</div>
      </div>
    </div>
    <div class="zhuti flex">
      <div class="remenck">
        <div class="mg24">
          <div class="cktitle"><i class="reicon"></i><span>物流车辆信息</span><span class="more" @click="golistwl(1)">查看更多<i class="el-icon-arrow-right"></i></span></div>
          <div>
            <el-tabs style="margin-top: 15px" v-model="activeName3" @tab-click="loadlist3">
              <el-tab-pane :label="li.title" :name="li.id" v-for="(li,index) in carTypeList" :key="index"></el-tab-pane>
            </el-tabs>
            <el-empty v-if="arr3.length==0"></el-empty>
            <div v-else>
              <div  class="cklist" v-for="(item,index) in arr3" @click="goxqwl(item)" :key="index">
                <div class="image"><el-image :src="item.carImage"></el-image></div>
                <div class="title text-overflow">{{item.carTypeName}}</div>
                <div class="money">{{item.price}} 元/单程</div>
                <div class="text-overflow"><span>可载重：{{item.loadWeight}}</span> <span class="address"> <i class="el-icon-location-outline"></i>{{item.departCityName}}</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="newck">
        <div class="mg24">
          <div class="cktitle"><span>新加入车辆</span><span class="more" @click="golistwl(1)">查看更多<i class="el-icon-arrow-right"></i></span></div>
          <div>
            <div  class="cklist flex" v-for="(item,index) in logisticsReleaseList" @click="goxqwl(item)" :key="index">
              <div class="image "><el-image :src="item.carImage"></el-image></div>
              <div>
                <div class="title text-overflow">{{item.carTypeName}}</div>
                <div class="money">{{item.price}} 元/单程</div>
                <div>可载重：{{item.loadWeight}}吨</div>
                <div class="text-overflow  address"> <i class="el-icon-location-outline"></i><span>{{item.departCityName}}</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bgf">
      <div class="zhuti baojia flex">
        <div class="flex-1">
          <div class="cktitle baojiadt">物流需求发布</div>
          <div>
            <el-table
                :data="logisticsDemandList"
                @row-click="rowclick1"
                style="width: 100%">
              <el-table-column prop="chufa" label="出发地">
              </el-table-column>
              <el-table-column prop="daoda" label="到达地" width="180">
              </el-table-column>
              <el-table-column prop="carTypeName" label="车辆类型" width="100">
              </el-table-column>
              <el-table-column prop="departTime" label="用车时间">
              </el-table-column>
              <el-table-column prop="loadWeight" label="载重(吨)">
              </el-table-column>
              <el-table-column prop="contacts" label="联系人">
              </el-table-column>
              <el-table-column prop="phone" label="联系方式">
              </el-table-column>
              <el-table-column prop="createTime" label="发布时间">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import config from "../../utils/config";
import {wareindex} from "../../request/moudle/logistics";

export default {
  name: "zchj",
  data(){
    return {
      numbers:0,
      tabs:[{name:'仓储',id:'m1'},{name:'物流',id:'m2'}],
      cangku:require('../../assets/cangku/img_3.png'),
      wuliu:require('../../assets/cangku/img_4.png'),
      activeName:' ',
      activeName3:' ',
      number:0,
      datas:'',
      newReleaseList:[],
      areaCountList:[],
      warehouseTypeList:[],
      rentalTypeList:[],
      logisticsReleaseList:[],
      logisticsDemandList:[],
      carTypeList:[],
      newsList:[],
      arr:[],
      arr1:[],
      arr3:[]
    }
  },
  created() {
    this.loadconfig()
  },
  methods:{
    scrollToAnchor(anchor) {
      this.$nextTick(() => {
        const anchorElement = document.getElementById(anchor);
        if (anchorElement) {
          anchorElement.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },
    tabchoose(id){
      this.scrollToAnchor(id)
    },
    rowclick1(row){
      this.$router.push({
        path:'/wlxq',
        query:{
          id:row.id,
          typeId:2,
        }
      })
    },
    gotolist(item){
      this.$router.push({
        path:'/gonggaolist',
        query:{
          type:this.datas.newsListType
        }
      })
    },
    gotoxq(ar){
      this.$router.push({
        path:'/gonggaoxq',
        query:{
          id:ar.id
        }
      })
    },
    goxqs(li){
        this.$router.push({
          path:'/logisticsxq',
          query:{
            id:li.id,
            typeId:2
          }
        })
    },
    goxq(li){
      this.$router.push({
        path:'/logisticsxq',
        query:{
          id:li.id,
          typeId:1
        }
      })
    },
    goxqwl(li){
      this.$router.push({
        path:'/wlxq',
        query:{
          id:li.id,
          typeId:1,
        }
      })
    },
    choosecheck(index){
      this.number=index
      this.loadlist2()
    },
    gohref(num){
        if(num==1){
          window.open(config.adminhref+'#/logistics/index','_blank')
        }else if(num==2){
          window.open(config.adminhref+'#/logistics/need','_blank')
        }else if(num==3){
          window.open(config.adminhref+'#/logistics/wlindex','_blank')
        }else if(num==4){
          window.open(config.adminhref+'#/logistics/wlneed','_blank')
        }
    },
    golist(num){
      this.$router.push({
        path:'/logisticslist',
        query:{
          typeId:num
        }
      })
    },
    golistwl(num){
      this.$router.push({
        path:'/wllist',
        query:{
          typeId:num
        }
      })
    },
    loadconfig(){
      this.$api.logistics.wareindex().then((res)=>{
          this.datas=res.data
          this.newReleaseList=res.data.newReleaseList
        this.carTypeList=res.data.carTypeList
          this.areaCountList=res.data.areaCountList
        this.logisticsReleaseList=res.data.logisticsReleaseList
        this.logisticsDemandList=res.data.logisticsDemandList
        for (const val of this.logisticsDemandList){
          val.chufa=`${val.departProvinceName}/${val.departCityName}/${val.departAreaName}`
          val.daoda=`${val.reachProvinceName}/${val.reachCityName}/${val.reachAreaName}`
        }
        this.warehouseTypeList=res.data.warehouseTypeList
        this.warehouseTypeList.unshift({ title: '全部', id: ' ' })
        this.carTypeList.unshift({ title: '全部', id: ' ' })
        this.activeName=this.warehouseTypeList[0].id
        this.activeName3=this.carTypeList[0].id
        this.rentalTypeList=res.data.rentalTypeList
        this.rentalTypeList.unshift({ title: '全部', id: ' ' })
        this.newsList=res.data.newsList
        this.loadlist1()
        this.loadlist2()
        this.loadlist3()
      })
    },
    loadlist1(bol){
      const obj={
      }
      obj.current=1
      obj.size=6
      obj.desc='read_count'
      obj.warehouseTypeId=this.activeName
      obj.typeId=1
      this.$api.logistics.warehouselist(obj).then((res)=>{
        this.arr=res.data.records
      })
    },
    loadlist2(bol){
      const obj={
      }
      obj.current=1
      obj.size=5
      obj.typeId=2
      obj.rentalTypeId=this.rentalTypeList[this.number].id
      this.$api.logistics.warehouselist(obj).then((res)=>{
        this.arr1=res.data.records
      })
    },
    loadlist3(bol){
      const obj={
      }
      obj.current=1
      obj.size=6
      obj.typeId=1
      obj.carTypeId=this.activeName3
      this.$api.logistics.logisticspage(obj).then((res)=>{
        this.arr3=res.data.records
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-table th.is-leaf{
  background-color: #fff!important;
}
.titles{
  text-align: center;
  font-size: 20px;
  padding: 25px 0;
  font-weight: bolder;
}
.fw{
  background:rgba(0,0,0,0.3);
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  width: 208px;
  height: 77px;
  position: absolute;
  font-weight: bolder;
  font-size: 22px;
  text-align: center;
  right: 0;
  top: 50%;
  margin-top:-38px ;
}
.fw1{
  left: 0;
}
.ying{
  font-size: 16px;
  font-weight: normal;
}
.bgf{
  background: #fff;
}
.typebg{
  background: url("../../assets/cangku/typebg.png");
  height: 516px;
  background-size: 100% 100%;
}
.el-icon-location-outline{
  font-size: 14px;
  margin-right: 2px;
  vertical-align: middle;
  margin-top: -2px;
}
.mg24{
  margin: 24px;
}
.font24{
  font-size: 24px;
  margin-top: 35px;
  width: 255px;
  margin-left: 20px;
  padding: 4px 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4) 51.43%, rgba(255, 255, 255, 0.00) 100%);
}
.header{
  height: 220px;
  background: url("../../assets/banner/logistics.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 90px;
  text-align: center;
  color: #fff;
  .h1{
    font-size: 42px;
    font-weight: 500;
  }
  .h2{
    font-size: 22px;
    line-height: 80px;
  }
}
.baojia{
  padding: 75px 0;
  .bjtitle{
    margin: 10px 0;
  }
  .baojiadt{
    margin-right: 25px!important;
  }
  .width50{
    width: 50%;
  }
  .cktitle{
    padding: 0 0 20px 0;
    margin: 0 0 20px 0;
    border-bottom: 1px solid #eee;
  }
  .bjlist{
    color: #999;
    padding: 10px 0;
  }

}
.ckxuqiu{
  color: #fff;
  .xuqiutitle{
    font-size: 20px;
    margin: 60px 0 32px 0;
  }
  .shichang{
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.30);
    background: #2E3653;
    .ul0{
      opacity: 0.5;
    }
    .ul1{
      padding: 16px 0;
    }
    .scleft{
      width: 186px;
      margin:24px;
      padding-right:24px ;
      border-right: 1px solid #bbbbbb;
      .ul{
        padding: 15px 18px;
        cursor: pointer;
      }
      .checked{
        background: #0c8aff;
        color: #fff;
        border-radius: 4px;
      }
    }
  }
}
.remenck{
  width: 786px;
  height: 648px;
  border-radius: 6px;
  background: #fff;
  margin-right: 8px;
  color: #666666;
  overflow: hidden;
  .cklist{
    float: left;
    margin: 5px;
    cursor: pointer;
    .image{
      width: 230px;
      height: 144px;
      margin: 10px 0;
    }
    .title{
      width: 230px;
      font-weight: bolder;
      margin-top: 12px;
    }
    .money{
      color: #FF3E01;
      font-weight: bolder;
      padding: 10px 0;
    }
    .address{
      float: right;
      font-size: 12px;
      opacity: 0.8;
    }
  }

}
.cktitle{
  font-size: 20px;
  .more{
    float: right;
    font-size: 14px;
    cursor: pointer;
  }
  .reicon{
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url("../../assets/cangku/img_2.png");
    background-size: 100% 100%;
    vertical-align: middle;
    margin: -7px 12px 0 0;
  }
}
.newck{
  width: 406px;
  height: 648px;
  border-radius: 6px;
  background: #fff;
  .cktitle{
    padding: 0 0 20px 0;
    border-bottom: 1px solid #eee;
  }
  .cklist{
    margin: 30px 0;
    cursor: pointer;
    .image{
      width:168px;
      height: 105px;
      margin-right: 12px;
    }
    .title{
      font-weight: bolder;
      margin-top: 3px;
      width: 178px;
    }
    .money{
      color: #FF3E01;
      font-weight: bolder;
      padding: 10px 0;
    }
    .address{
      font-size: 12px;
      opacity: 0.8;
      margin-top: 5px;
    }
  }
}
.ckdata{
  padding: 40px 0;
  color: #fff;
  .ckleft{
    width: 588px;
    background: url("../../assets/cangku/ckleft.png") no-repeat;
    background-size: 100% 100%;
    height: 204px;
    margin: 35px 20px;

  }
  .ckright{
    margin-left: 20px;
    background: url("../../assets/cangku/ckright.png") no-repeat;
    background-size: 100% 100%;
  }
  .cktitle{
    font-size: 12px;
    opacity: 0.8;
    line-height: 35px;
    margin-top: 40px;
  }
  .cknumber{
    font-size: 20px;
  }
  .img{
    display: inline-block;
    width: 26px;
    height: 26px;
    background: url("../../assets/cangku/img.png");
    background-size: 100% 100%;
    vertical-align: middle;
    margin: 0 10px;
  }
  .img_1{
    display: inline-block;
    width: 26px;
    height: 26px;
    background: url("../../assets/cangku/img_1.png");
    background-size: 100% 100%;
    vertical-align: middle;
    margin: 0 10px;
  }
}
.news{
  width: 406px;
  overflow: hidden;
  .newsbtn{
    width: 368px;
    height: 121px;
    border-radius: 8px;
    background: #0079E9;
    line-height: 121px;
    text-align: center;
    margin-left: 8px;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
  }
  .bgcolor{
    background: #F37500;
    margin-top: 41px;
  }
  .newsbg{
    display: inline-block;
    width: 71px;
    height: 71px;
    background: url("../../assets/cangku/newsbg.png");
    background-size: 100% 100%;
    margin-right: 40px;
    margin-top: -5px;
    vertical-align: middle;
  }
  .newsbg1{
    background: url("../../assets/cangku/newsbg1.png");
    background-size: 100% 100%;
  }
  .newsbox{
    width: 354px;
    background: rgba(255,255,255,1);
    border-radius: 8px;
    padding: 20px;
    margin-left: 8px;
    margin-top: 8px;
    color: #333;
    .zxbg{
      display: inline-block;
      width: 96px;
      height: 24px;
      background-size: cover;
      margin-bottom: 10px;
      font-weight: bolder;
      font-size: 16px;
    }

    .news-title{
      border-bottom: 1px dashed rgba(255,255,255,.3);
      padding-left: 5px;
      cursor: pointer;
      .title{
        width: 320px;
        padding:6px 0;
      }
    }
  }
}
.zhuti{
  width: 1200px;
  margin:30px auto;
  overflow: hidden;
}
.tabs{
  overflow: hidden;
  border-bottom: 1px solid #eee;
  .tablist{
    font-size: 16px;
    font-weight: bolder;
    text-align: center;
    cursor: pointer;
  }
  span{
    display: inline-block;
    padding: 15px;
  }
  .isactive{
    border-bottom: 2px solid #0c8aff;
  }
}
</style>